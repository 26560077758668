import { FC, useState } from "react";

const Stack: FC = () => {
    const [activeTab, setActiveTab] = useState("Backend");

    const tabs = [
      { name: "Backend", content: 
      <div className="p-8 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 w-full gap-8 overflow-hidden">
      <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
        <img
          style={{
            height: "100px",
            width: "100%",
            objectFit: "contain",
          }}
          className="w-full h-[150px]"
          src={require("../../../assets/images/java.png")}
          alt="..."
        />
      </a>

      <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
        <img
          style={{
            height: "100px",
            width: "100%",
            objectFit: "contain",
          }}
          className="w-full h-[150px]"
          src={require("../../../assets/images/net.png")}
          alt="..."
        />
      </a>

      <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
        <img
          style={{
            height: "100px",
            width: "100%",
            objectFit: "contain",
          }}
          className="w-full h-[150px]"
          src={require("../../../assets/images/php.png")}
          alt="..."
        />
      </a>
      <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
        <img
          style={{
            height: "100px",
            width: "100%",
            objectFit: "contain",
          }}
          className=" w-full h-[150px]"
          src={require("../../../assets/images/rubby.png")}
          alt="..."
        />
      </a>

      <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
        <img
          style={{
            height: "80px",
            width: "100%",
            objectFit: "contain",
          }}
          className="w-full h-[150px]"
          src={require("../../../assets/images/next.png")}
          alt="..."
        />
      </a> 
      <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
        <img
          style={{
            height: "80px",
            width: "100%",
            objectFit: "contain",
          }}
          className="w-full h-[150px]"
          src={require("../../../assets/images/python.png")}
          alt="..."
        />
      </a> 
    </div> },
      { name: "Frontend", content: 
      <div className="p-8 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 w-full gap-8 overflow-hidden">
      <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
        <img
          style={{
            height: "100px",
            width: "100%",
            objectFit: "contain",
          }}
          className="w-full h-[150px]"
          src={require("../../../assets/images/javascript.png")}
          alt="..."
        />
      </a>

      <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
        <img
          style={{
            height: "100px",
            width: "100%",
            objectFit: "contain",
          }}
          className="w-full h-[150px]"
          src={require("../../../assets/images/angular.png")}
          alt="..."
        />
      </a>

      <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
        <img
          style={{
            height: "100px",
            width: "100%",
            objectFit: "contain",
          }}
          className="w-full h-[150px]"
          src={require("../../../assets/images/react.png")}
          alt="..."
        />
      </a>
      <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
        <img
          style={{
            height: "100px",
            width: "100%",
            objectFit: "contain",
          }}
          className=" w-full h-[150px]"
          src={require("../../../assets/images/11ty.png")}
          alt="..."
        />
      </a>

      <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
        <img
          style={{
            height: "80px",
            width: "100%",
            objectFit: "contain",
          }}
          className="w-full h-[150px]"
          src={require("../../../assets/images/tailwind.png")}
          alt="..."
        />
      </a>  
    </div> },
      { name: "Databases", content: 
      <div className="p-8 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 w-full gap-8 overflow-hidden">
      <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
        <img
          style={{
            height: "100px",
            width: "100%",
            objectFit: "contain",
          }}
          className="w-full h-[150px]"
          src={require("../../../assets/images/mysql.png")}
          alt="..."
        />
      </a>

      <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
        <img
          style={{
            height: "100px",
            width: "100%",
            objectFit: "contain",
          }}
          className="w-full h-[150px]"
          src={require("../../../assets/images/mongo.png")}
          alt="..."
        />
      </a>

      <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
        <img
          style={{
            height: "100px",
            width: "100%",
            objectFit: "contain",
          }}
          className="w-full h-[150px]"
          src={require("../../../assets/images/postgresql.png")}
          alt="..."
        />
      </a>
      <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
        <img
          style={{
            height: "100px",
            width: "100%",
            objectFit: "contain",
          }}
          className=" w-full h-[150px]"
          src={require("../../../assets/images/micro.png")}
          alt="..."
        />
      </a> 
    </div> },
      { name: "CMS", content: 
      <div className="p-8 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 w-full gap-8 overflow-hidden">
      <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
        <img
          style={{
            height: "100px",
            width: "100%",
            objectFit: "contain",
          }}
          className="w-full h-[150px]"
          src={require("../../../assets/images/wordpress.png")}
          alt="..."
        />
      </a>

      <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
        <img
          style={{
            height: "100px",
            width: "100%",
            objectFit: "contain",
          }}
          className="w-full h-[150px]"
          src={require("../../../assets/images/magento.png")}
          alt="..."
        />
      </a>

      <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
        <img
          style={{
            height: "100px",
            width: "100%",
            objectFit: "contain",
          }}
          className="w-full h-[150px]"
          src={require("../../../assets/images/contentful.png")}
          alt="..."
        />
      </a> 
    </div> },
    ];
    
  return (
    <div className="mt-10 mb-20"> 
    <h2 className="text-4xl font-light tracking-tight text-gray-900 sm:text-6xl">
    Our <span className="text-primary-600">Tech </span>
<span className="text-primary-800">Stack</span>
</h2>
    <div className="md:flex p-8"> 

      <ul className="flex-column space-y space-y-4 text-sm font-medium text-gray-500 dark:text-gray-400 md:me-4 mb-4 md:mb-0">
      {tabs.map((tab) => (
      <li  key={tab.name}>
          <a 
            onClick={() => setActiveTab(tab.name)}
            className={`inline-flex items-center px-4 py-3 rounded-lg w-full ${
                activeTab === tab.name
                  ? "text-white bg-primary-800 dark:bg-primary-600"
                  : "text-white hover:text-white bg-primary-50 hover:bg-primary-800 dark:bg-primary-800 dark:hover:bg-primary-700 dark:hover:text-white"
              }`}
            aria-current={activeTab === tab.name ? "page" : undefined}
          >
            <svg
              className="w-4 h-4 me-2 text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="#ffffff"
              viewBox="0 0 20 20"
            >
                              {tab.name === "Backend" && (
                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                  )}
                  {tab.name === "Frontend" && (
                    <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                  )}
                  {tab.name === "Databases" && (
                    <path d="M18 7.5h-.423l-.452-1.09.3-.3a1.5 1.5 0 0 0 0-2.121L16.01 2.575a1.5 1.5 0 0 0-2.121 0l-.3.3-1.089-.452V2A1.5 1.5 0 0 0 11 .5H9A1.5 1.5 0 0 0 7.5 2v.423l-1.09.452-.3-.3a1.5 1.5 0 0 0-2.121 0L2.576 3.99a1.5 1.5 0 0 0 0 2.121l.3.3L2.423 7.5H2A1.5 1.5 0 0 0 .5 9v2A1.5 1.5 0 0 0 2 12.5h.423l.452 1.09-.3.3a1.5 1.5 0 0 0 0 2.121l1.415 1.413a1.5 1.5 0 0 0 2.121 0l.3-.3 1.09.452V18A1.5 1.5 0 0 0 9 19.5h2a1.5 1.5 0 0 0 1.5-1.5v-.423l1.09-.452.3.3a1.5 1.5 0 0 0 2.121 0l1.415-1.414a1.5 1.5 0 0 0 0-2.121l-.3-.3.452-1.09H18a1.5 1.5 0 0 0 1.5-1.5V9A1.5 1.5 0 0 0 18 7.5Zm-8 6a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Z" />
                  )}
                  {tab.name === "CMS" && (
                    <path d="M17 7A7 7 0 1 0 3 7a3 3 0 0 0-3 3v2a3 3 0 0 0 3 3h1a1 1 0 0 0 1-1V7a5 5 0 1 1 10 0v7.083A2.92 2.92 0 0 1 12.083 17H12a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h1a1.993 1.993 0 0 0 1.722-1h.361a4.92 4.92 0 0 0 4.824-4H17a3 3 0 0 0 3-3v-2a3 3 0 0 0-3-3Z"/>
                 
                  )}
                </svg>
            {tab.name}
          </a>
        </li> 
          ))}
      </ul> 
      <div className="p-6 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full"> 
        <h3 className="text-lg font-bold text-white dark:text-white mb-2">
        {activeTab}
        </h3>
        <p>{tabs.find((tab) => tab.name === activeTab)?.content}</p>
      </div>
    </div>
    </div>
  );
};

export default Stack;
