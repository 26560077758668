import { FC } from "react";
import { useTranslation } from "react-i18next";

const Timeline: FC = () => {
  const { t } = useTranslation();

  return (
    <section>
      <div className="relative h-[100%] md:h-[600px] hidden md:block">
        <button
          type="button"
          className="inline-flex items-center gap-x-1 text-xl font-semibold leading-6 text-gray-900"
          aria-expanded="false"
        >
          <span>{t("howWorks")}</span>
        </button>
        <div className="absolute z-10 mt-5 flex items-center justify-center">
          <div className="w-screen max-w-[70%] flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/6">
            <div className="p-4">
              <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                <div className="mt-1 bg-primary-600 text-white flex h-11 w-11 flex-none items-center justify-center rounded-lg group-hover:bg-primary-50">
                  #1
                </div>
                <div>
                  <a className="font-semibold text-gray-900">
                  {t("assemble")}
                    <span className="absolute inset-0"></span>
                  </a>
                  <p className="mt-1 text-gray-600">
                  {t("handleAll")}
                  </p>
                </div>
              </div>
              <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                <div className="mt-1 bg-primary-600 text-white flex h-11 w-11 flex-none items-center justify-center rounded-lg group-hover:bg-primary-50">
                  #2
                </div>
                <div>
                  <a className="font-semibold text-gray-900">
                  {t("sprint")}
                    <span className="absolute inset-0"></span>
                  </a>
                  <p className="mt-1 text-gray-600">
                  {t("roadmap")}
                  </p>
                </div>
              </div>
              <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                <div className="mt-1 bg-primary-600 text-white flex h-11 w-11 flex-none items-center justify-center rounded-lg group-hover:bg-primary-50">
                  #3
                </div>
                <div>
                  <a className="font-semibold text-gray-900">
                  {t("techArch")}
                    <span className="absolute inset-0"></span>
                  </a>
                  <p className="mt-1 text-gray-600">
                  {t("break")}
                  </p>
                </div>
              </div>
              <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                <div className="mt-1 bg-primary-600 text-white flex h-11 w-11 flex-none items-center justify-center rounded-lg group-hover:bg-primary-50">
                  #4
                </div>
                <div>
                  <a className="font-semibold text-gray-900">
                  {t("standups")}
                    <span className="absolute inset-0"></span>
                  </a>
                  <p className="mt-1 text-gray-600">
                  {t("weekly")}
                  </p>
                </div>
              </div>
              <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                <div className="mt-1 bg-primary-600 text-white flex h-11 w-11 flex-none items-center justify-center rounded-lg group-hover:bg-primary-50">
                  #5
                </div>
                <div>
                  <a className="font-semibold text-gray-900">
                  {t("codeRev")}
                    <span className="absolute inset-0"></span>
                  </a>
                  <p className="mt-1 text-gray-600">
                  {t("release")}
                  </p>
                </div>
              </div>
              <div className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                <div className="mt-1 bg-primary-600 text-white flex h-11 w-11 flex-none items-center justify-center rounded-lg group-hover:bg-primary-50">
                  #6
                </div>
                <div>
                  <a className="font-semibold text-gray-900 pl-40">
                  {t("iterative")}
                    <span className="absolute inset-0"></span>
                  </a>
                  <p className="mt-1 text-gray-600">
                  {t("divide")}
                  </p>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
              <a
                href="#projects"
                className="flex items-center bg-primary-800 justify-center gap-x-2.5 p-3 font-semibold text-white hover:bg-primary-50"
              >
                <svg
                  className="h-5 w-5 flex-none text-white"
                  viewBox="0 0 20 20"
                  fill="white"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 10a8 8 0 1116 0 8 8 0 01-16 0zm6.39-2.908a.75.75 0 01.766.027l3.5 2.25a.75.75 0 010 1.262l-3.5 2.25A.75.75 0 018 12.25v-4.5a.75.75 0 01.39-.658z"
                    clip-rule="evenodd"
                  />
                </svg>
                {t("latestProject")}
              </a>
              <a
                href="#"
                className="flex items-center bg-primary-600 justify-center gap-x-2.5 p-3 font-semibold text-white hover:bg-primary-50"
              >
                <svg
                  className="h-5 w-5 flex-none text-white"
                  viewBox="0 0 20 20"
                  fill="white"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z"
                    clip-rule="evenodd"
                  />
                </svg>
                {t("callUs")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="mx-auto py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
            <svg
              viewBox="0 0 1024 1024"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
              aria-hidden="true"
            >
              <circle
                cx="512"
                cy="512"
                r="512"
                fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                fill-opacity="0.7"
              />
              <defs>
                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                  <stop stop-color="#7775D6" />
                  <stop offset="1" stop-color="#E935C1" />
                </radialGradient>
              </defs>
            </svg>
            <div className="mx-auto text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              {t("bestDevelopers")}
              </h2>
              <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                <a
                  href="tel:+39 351 3841773"
                  className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  {t("contactUs")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Timeline;
