import { FC } from "react";
import HeroSection from "./hero-section/hero-section";
import Services from "./services/services";
import Projects from "./projects/projects";
import Development from "./development/development";
import Stack from "./stack/stack";
import Timeline from "./timeline/timeline";

const Home: FC = () => { 
    return (
        <div>
            <HeroSection />
            <Services />
            <Projects />
            <Development />
            <Stack />
            <Timeline />
        </div>
    )
}

export default Home;