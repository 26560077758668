import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  lng: "it",
  fallbackLng: "it",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
        translation: {
            great: 'Great',
            subtitle: 'We help build and manage a team of world-class developers to bring your vision to life',
            software: 'software',
            builtby: 'is built by great',
            team: 'team',
            getStarted: 'Get started',
            services: 'Services we offer',
            webDesign: 'Web Design & Development',
            webDescr: 'We specialize in creating visually appealing and highly functional websites. Our team ensures your site is optimized for both user experience and search engines, making it easy for your audience to find and navigate. From concept to launch, we deliver custom web solutions tailored to your business needs.',
            mobile: 'Mobile App Development',
            mobileDescr: 'Our mobile app development service brings your ideas to life on iOS and Android platforms. We design intuitive and engaging apps that provide seamless user experiences. Whether you need a new app or want to enhance an existing one, we offer end-to-end development and support.',
            ecommerce: 'E-commerce Solutions',
            ecommerceDescr: 'Our e-commerce solutions help you establish a robust online store. We integrate secure payment gateways, design user-friendly interfaces, and ensure your platform is scalable to handle growth. From product listings to order management, we cover all aspects of e-commerce.',
            uiux: 'UI/UX Design',
            uiDescr: 'Our UI/UX design service focuses on creating intuitive and aesthetically pleasing interfaces. We prioritize user experience, ensuring that your product is both functional and enjoyable to use. We conduct thorough research and testing to refine designs that meet your users needs',
            maintenance: 'Software Maintenance & Support',
            mainDescr: 'We provide ongoing maintenance and support for your software to ensure it runs smoothly and efficiently. Our team handles updates, bug fixes, and performance optimizations, allowing you to focus on your core business while we take care of the technical aspects.',
            seo: 'Search Engine Optimization (SEO)',
            seoDescr: 'Our SEO services are designed to boost your websites visibility and search engine rankings. Through a meticulous approach, we analyze your website, research relevant keywords, optimize on-page elements, and build high-quality backlinks to improve your organic traffic.',
            leading: 'Leading companies trust us to develop software',
            development: 'We add development capacity to tech teams. Our value isn’t limited to building teams but is equally distributed across the project lifecycle. We are a custom software development company that guarantees the successful delivery of your project.',
            people: 'Meet the People We are Working With',
            ourDesign: 'Our design and development approach',
            uxDriven: 'UX Driven Engineering',
            uxDrivenDescr: 'Unlike other companies, we are a UX first development company. Projects are driven by designers and they make sure design and experiences translate to code.',
            developing: 'Developing Shared Understanding',
            developingDescr: 'We foster a collaborative environment where all stakeholders gain a comprehensive understanding of the project goals and requirements. Through effective communication and shared knowledge, we ensure everyone is aligned towards a common vision.',
            proven: 'Proven Experience and Expertise',
            provenDescr: 'With a track record of successful projects and a team of seasoned professionals, we bring proven experience and expertise to every endeavor. Our wealth of knowledge allows us to tackle complex challenges and deliver outstanding results.',
            security: 'Security & Intellectual Property (IP)',
            securityDescr: 'We prioritize the security of your data and intellectual property (IP) throughout the development process. Our stringent measures and protocols ensure that your assets are safeguarded against unauthorized access and theft.',
            reviewers: 'Code Reviews',
            reviewersDescr: 'Our thorough code review process ensures the quality and maintainability of our software solutions. By scrutinizing every line of code, we identify and rectify issues early on, guaranteeing a robust and efficient final product.',
            quality: 'Quality Assurance & Testing',
            qualityDescr: 'We adhere to rigorous quality assurance and testing standards to deliver reliable and flawless software. From functional testing to performance optimization, we meticulously evaluate every aspect of our products to ensure they meet the highest standards of quality and reliability.',
            howWorks: 'How development through Matrix works',
            assemble: 'Assemble the right team',
            handleAll: ' We handle all aspects of vetting and choosing the right team that you don`t have the time, expertise, or desire to do.',
            sprint: 'Sprint planning',
            roadmap: 'Sprint roadmap is a collective planning effort. Team members collaborate to clarify items and ensure shared understanding.',
            techArch: 'Tech architecture',
            break: 'We break monolithic apps into microservices. Decoupling the code allows teams to move faster and more independently',
            standups: 'Standups & weekly demos',
            weekly: 'Standups, weekly demos, and weekly reviews make sure everyone is on the same page and can raise their concerns.',
            codeRev: 'Code reviews',
            release: 'Code reviews before release help detect issues like memory leaks, file leaks, performance signs, and general bad smells',
            iterative: 'Iterative delivery',
            divide: 'We divide the implementation process into several checkpoints rather than a single deadline.',
            latestProject: 'Watch our latest projects',
            callUs: 'Call Us',
            bestDevelopers: 'Work with the best developers and designers around!',
            contactUs: 'Contact Us'
        }
    },
    al: {
        translation: {
            great: 'Softueri',
            subtitle: 'Ne ndihmojmë në ndërtimin dhe menaxhimin e një ekipi zhvilluesish të klasit botëror për të sjellë në jetë vizionin tuaj',
            software: 'i shkëlqyer',
            builtby: 'ndërtuar nga më e mira',
            team: 'skuadër',
            getStarted: 'Fillo',
            services: 'Shërbimet që ne ofrojmë',
            webDesign: 'Dizajn dhe Zhvillim Uebi', 
            webDescr: 'Ne jemi të specializuar në krijimin e faqeve të internetit vizualisht tërheqëse dhe shumë funksionale. Ekipi ynë siguron që faqja juaj të jetë e optimizuar si për përvojën e përdoruesit ashtu edhe për motorët e kërkimit, duke e bërë të lehtë për audiencën tuaj ta gjejë dhe navigojë. Nga konceptimi deri te publikimi, ne ofrojmë zgjidhje të personalizuara të uebit të përshtatura për nevojat e biznesit tuaj.',
            mobile: 'Zhvillimi i Aplikacioneve Mobile',
            mobileDescr: 'Shërbimi ynë i zhvillimit të aplikacioneve mobile sjell idetë tuaja në jetë në platformat iOS dhe Android. Ne dizajnojmë aplikacione intuitive dhe tërheqëse që ofrojnë përvoja të pandërprera për përdoruesit. Qoftë që keni nevojë për një aplikacion të ri apo dëshironi të përmirësoni një ekzistues, ne ofrojmë zhvillim dhe mbështetje të plotë.',
            ecommerce: 'Zgjidhje për E-commerce',
            ecommerceDescr: 'Zgjidhjet tona për e-commerce ju ndihmojnë të krijoni një dyqan online të fortë. Ne integrojmë porta të sigurta pagese, dizajnojmë ndërfaqe miqësore për përdoruesit dhe sigurojmë që platforma juaj të jetë e shkallëzueshme për të përballuar rritjen. Nga listat e produkteve te menaxhimi i porosive, ne mbulojmë të gjitha aspektet e e-commerce.',
            uiux: 'UI/UX Design',
            uiDescr: 'Shërbimi ynë i dizajnit UI/UX fokusohet në krijimin e ndërfaqeve intuitive dhe estetikisht të këndshme. Ne prioritetizojmë përvojën e përdoruesit, duke siguruar që produkti juaj të jetë funksional dhe i këndshëm për tu përdorur. Ne kryejmë kërkime dhe testime të hollësishme për të përmirësuar dizajnet që plotësojnë nevojat e përdoruesve tuaj',
            maintenance: 'Software Maintenance & Support',
            mainDescr: 'Ne ofrojmë mirëmbajtje dhe mbështetje të vazhdueshme për softuerin tuaj për të siguruar që ai të funksionojë pa probleme dhe me efikasitet. Ekipi ynë merret me përditësimet, rregullimet e gabimeve dhe optimizimet e performancës, duke ju lejuar të përqendroheni në biznesin tuaj kryesor ndërsa ne kujdesemi për aspektet teknike.',
            seo: 'Optimizimi për Motorët e Kërkimit (SEO)',
            seoDescr: 'Shërbimet tona SEO janë të dizajnuara për të rritur dukshmërinë e faqes suaj të internetit dhe renditjen në motorët e kërkimit. Përmes një qasjeje të kujdesshme, ne analizojmë faqen tuaj, kërkojmë fjalë kyçe të rëndësishme, optimizojmë elementët në faqe dhe ndërtojmë lidhje të cilësisë së lartë për të përmirësuar trafikun tuaj organik.',
            leading: 'Kompani të njohura na besojnë për zhvillimin e softuerit',
            development: 'Ne rrisim kapacitetin e zhvillimit të ekipeve teknologjike. Vlera jonë nuk kufizohet vetëm në ndërtimin e ekipeve, por shpërndahet në mënyrë të barabartë gjatë gjithë ciklit të jetës së projektit. Ne jemi një kompani e zhvillimit të softuerit të personalizuar që garanton dorëzimin e suksesshëm të projektit tuaj.',
            people: 'Takoni Njerëzit që po punojnë me ne',
            ourDesign: 'Qasja jonë në dizajn dhe zhvillim',
            uxDriven: 'UX Driven Engineering',
            uxDrivenDescr: 'Ndryshe nga kompanitë e tjera, ne e përparojmë përvojën e përdoruesit (UX) në krye të procesit tonë të zhvillimit. Projektet tona drejtohen nga dizajnerët që sigurojnë që dizajnet përkthehen pa problem në kod, rezultojnë në produkte intuitive dhe të lehta për tu përdorur.',
            developing: 'Developing Shared Understanding',
            developingDescr: 'Ne kultivojmë një mjedis bashkëpunues ku të gjithë palët fitojnë një kuptim të plotë të qëllimeve dhe kërkesave të projektit. Përmes komunikimit efektiv dhe njohurive të përbashkëta, ne sigurojmë që të gjithë të jenë të përllogaritur në drejtim të një vizioni të përbashkët.',
            proven: 'Proven Experience and Expertise',
            provenDescr: 'Me një historik të suksesshëm të projekteve dhe një ekip profesionistësh të përvojuar, sjellim përvojën dhe ekspertizën e provuar në çdo përpjekje. Pasuria jonë e njohurive na lejon të përballim sfida të ndërlikuara dhe të sigurojmë rezultate të shkëlqyera.',
            security: 'Security & Intellectual Property (IP)',
            securityDescr: 'Ne e përparojmë sigurinë e të dhënave tuaja dhe të pronës intelektuale (IP) gjatë gjithë procesit të zhvillimit. Masat dhe protokollet tona rigorozë sigurojnë që pasuritë tuaja mbrohen kundër hyrjes së paautorizuar dhe vjedhjes.',
            reviewers: 'Code Reviews',
            reviewersDescr: 'Procesi ynë i rishikimit të detajuar të kodit siguron cilësinë dhe aftësinë për mirëmbajtje të zgjidhjeve tona softuerike. Duke ekzaminuar çdo linjë kod, identifikojmë dhe korrigjojmë problemet në fillim, duke garantuar një produkt final të qëndrueshëm dhe efikas.',
            quality: 'Quality Assurance & Testing',
            qualityDescr: 'Ne respektojmë standarde rigorozë të sigurimit të cilësisë dhe testim për të ofruar softuer të besueshëm dhe pa defekte. Duke filluar nga testimi funksional deri te optimizimi i performancës, ne e vlerësojmë me kujdes çdo aspekt të produktit tonë për të siguruar që përmbushin standardet më të larta të cilësisë dhe besueshmërisë.',
            howWorks: 'Si funksionon zhvillimi përmes Matrix',
            assemble: 'Marrim ekipin e duhur',
            handleAll: ' Ne trajtojmë të gjitha aspektet e përzgjedhjes dhe zgjedhjes së ekipit të duhur, duke ju kursyer kohë dhe duke garantuar kompetenca që ndoshta nuk i keni ose nuk dëshironi t`i trajtoni.',
            sprint: 'Planifikimi i sprintit',
            roadmap: 'Plani i sprintit është një përpjekje kolektive e planifikimit. Anëtarët e ekipit bashkëpunojnë për të sqaruar elementet dhe për të siguruar një kuptim të përbashkët.',
            techArch: 'Arkitektura teknologjike',
            break: 'Ne i ndarim aplikacionet monolitike në mikrosherbime. Dekoplimi i kodit lejon ekipet të lëvizin më shpejt dhe më në mënyrë të pavarur.',
            standups: 'Standup dhe demonstrime javore',
            weekly: 'Standup, demonstrimet javore dhe rishikimet javore sigurojnë që të gjithë të jenë në të njëjtën faqe dhe të mund të ngrejnë shqetësimet e tyre.',
            codeRev: 'Rishikimi i kodit',
            release: 'Rishikimet e kodit para lëshimit ndihmojnë në zbulimin e problemeve si rrjedhjet e memories, rrjedhjet e skedarëve, shenjat e performancës dhe problemet e përgjithshme të kodit.',
            iterative: 'Dorëzimi iterativ',
            divide: 'Ne e ndajmë procesin e implementimit në disa pika kontrolli në vend të një afati të vetëm.',
            latestProject: 'Shikoni projektet tona më të fundit',
            callUs: 'Na telefononi',
            bestDevelopers: 'Punoni me zhvilluesit më të mirë!',
            contactUs: 'Na Kontaktoni'
        
        }
    },
    it: {
      translation: {
        great: "Il grande",
        subtitle: "Aiutiamo a costruire e gestire un team di sviluppatori di livello mondiale per dare vita alla tua visione",
        software: "software",
        builtby: "è creato da grandi",
        team: 'team',
        getStarted: 'Iniziamo',
        services: 'Servizi che offriamo',
        webDesign: 'Progettazione e Sviluppo Web', 
        webDescr: 'Siamo specializzati nella creazione di siti web visivamente attraenti e altamente funzionali. Il nostro team garantisce che il tuo sito sia ottimizzato sia per l`esperienza utente che per i motori di ricerca, rendendolo facile da trovare e navigare per il tuo pubblico. Dall`ideazione al lancio, offriamo soluzioni web personalizzate su misura per le esigenze del tuo business.',
        mobile: 'Sviluppo di App Mobile',
        mobileDescr: 'Il nostro servizio di sviluppo di app mobile trasforma le tue idee in realtà su piattaforme iOS e Android. Progettiamo app intuitive e coinvolgenti che offrono esperienze utente senza soluzione di continuità. Che tu abbia bisogno di una nuova app o di migliorare una già esistente, offriamo sviluppo e supporto completo.',
        ecommerce: 'Soluzioni di E-commerce',
        ecommerceDescr: 'Le nostre soluzioni di e-commerce ti aiutano a stabilire un negozio online robusto. Integriamo gateway di pagamento sicuri, progettiamo interfacce user-friendly e garantiamo che la tua piattaforma sia scalabile per gestire la crescita. Dai listini dei prodotti alla gestione degli ordini, copriamo tutti gli aspetti dell`e-commerce.',
        uiux: 'UI/UX Design',
        uiDescr: 'Il nostro servizio di design UI/UX si concentra sulla creazione di interfacce intuitive ed esteticamente piacevoli. Priorizziamo l`esperienza utente, garantendo che il tuo prodotto sia sia funzionale che piacevole da usare. Conduciamo ricerche e test approfonditi per perfezionare i design che soddisfano le esigenze dei tuoi utenti.',
        maintenance: 'Manutenzione e Supporto Software',
        mainDescr: 'Forniamo manutenzione e supporto continuo per il tuo software per garantire che funzioni senza problemi ed efficientemente. Il nostro team si occupa di aggiornamenti, correzioni di bug e ottimizzazioni delle prestazioni, permettendoti di concentrarti sul tuo core business mentre noi ci occupiamo degli aspetti tecnici.',
        seo: 'Ottimizzazione per i Motori di Ricerca (SEO)',
        seoDescr: 'I nostri servizi SEO sono progettati per aumentare la visibilità del tuo sito web e il posizionamento nei motori di ricerca. Attraverso un approccio meticoloso, analizziamo il tuo sito, ricerchiamo parole chiave pertinenti, ottimizziamo gli elementi on-page e costruiamo backlink di alta qualità per migliorare il tuo traffico organico.',
        leading: 'Le aziende leader si affidano a noi per lo sviluppo del software',
        development: 'Aumentiamo la capacità di sviluppo dei team tecnologici. Il nostro valore non si limita alla costruzione dei team, ma è distribuito equamente lungo tutto il ciclo di vita del progetto. Siamo una società di sviluppo software personalizzato che garantisce la consegna di successo del tuo progetto.',
        people: 'Incontra le Persone Stiamo Lavorando Con',   
        ourDesign: 'Il nostro approccio al design e allo sviluppo',
        uxDriven: 'UX Driven Engineering',
        uxDrivenDescr: 'A differenza di altre aziende, diamo priorità all`esperienza utente (UX) all`avanguardia del nostro processo di sviluppo. I nostri progetti sono guidati dai designer che si assicurano che i design si traducano senza soluzione di continuità in codice, risultando in prodotti intuitivi e facili da usare.',
        developing: 'Creare una Comprensione Condivisa',
        developingDescr: 'Promuoviamo un ambiente collaborativo in cui tutte le parti interessate acquisiscono una comprensione completa degli obiettivi e dei requisiti del progetto. Attraverso una comunicazione efficace e la condivisione delle conoscenze, ci assicuriamo che tutti siano allineati verso una visione comune.',
        proven: 'Esperienza ed Expertise Provate',
        provenDescr: 'Con un track record di progetti di successo e un team di professionisti esperti, portiamo esperienza ed expertise provate in ogni impegno. La nostra vasta conoscenza ci consente di affrontare sfide complesse e ottenere risultati eccezionali.',
        security: 'Sicurezza e Proprietà Intellettuale (IP)',
        securityDescr: 'Diamo priorità alla sicurezza dei tuoi dati e della proprietà intellettuale (IP) durante tutto il processo di sviluppo. Le nostre misure e protocolli rigorosi garantiscono che i tuoi asset siano protetti contro l`accesso non autorizzato e il furto.',
        reviewers: 'Revisione del Codice',
        reviewersDescr: 'Il nostro approfondito processo di revisione del codice garantisce la qualità e la manutenibilità delle nostre soluzioni software. Analizzando ogni riga di codice, identifichiamo e correggiamo i problemi in anticipo, garantendo un prodotto finale robusto ed efficiente.',
        quality: 'Assicurazione della Qualità e Test',
        qualityDescr: 'Ci atteniamo a rigorosi standard di assicurazione della qualità e test per fornire software affidabili e senza difetti. Dalla verifica funzionale all`ottimizzazione delle prestazioni, valutiamo attentamente ogni aspetto dei nostri prodotti per garantire che rispettino gli standard più elevati di qualità e affidabilità.',
        howWorks: 'Come funziona lo sviluppo attraverso Matrix',
        assemble: 'Assembliamo il team giusto',
        handleAll: 'Gestiamo tutti gli aspetti della selezione e scelta del team giusto, risparmiandoti tempo e garantendo competenze che forse non hai o non desideri affrontare.',
        sprint: 'Pianificazione dello sprint',
        roadmap: 'La pianificazione dello sprint è uno sforzo di pianificazione collettiva. I membri del team collaborano per chiarire gli elementi e garantire una comprensione condivisa.',
        techArch: 'Architettura tecnologica',
        break: 'Scomponiamo le applicazioni monolitiche in microservizi. Decoupling del codice permette ai team di muoversi più velocemente e in modo più indipendente.',
        standups: 'Standup e demo settimanali',
        weekly: 'Standup, demo settimanali e revisioni settimanali assicurano che tutti siano sulla stessa lunghezza d`onda e possano sollevare le loro preoccupazioni.',
        codeRev: 'Revisione del codice',
        release: 'Le revisioni del codice prima del rilascio aiutano a rilevare problemi come perdite di memoria, perdite di file, segnali di prestazioni e odori generali di codice.',
        iterative: 'Consegna iterativa',
        divide: 'Dividiamo il processo di implementazione in diversi checkpoint anziché in una singola scadenza.',
        latestProject: 'Guarda i nostri ultimi progetti',
        callUs: 'Chiamaci',
        bestDevelopers: 'Lavora con i migliori sviluppatori e designer in circolazione!',
        contactUs: 'Contattaci'
    
      },
    },
  },
});

export default i18n;