import { FC } from "react";
import { useTranslation } from "react-i18next";

const HeroSection: FC = () => {
    const { t } = useTranslation();
    
    return ( 
            <div className="relative   px-6 pt-8 lg:px-8">
                <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
                    <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"></div>
                </div>
                <div className="flex mx-auto  py-16 sm:py-48 lg:py-40">
                    <div className="w-full lg:w-1/2 mb-8 lg:mb-0">  
                    <div className="text-left">
                        <h1 className="text-4xl font-light tracking-tight text-gray-900 sm:text-6xl">{t("great")} <span className="text-primary-600">{t("software")}</span> {t("builtby")} <span className="text-primary-800">{t("team")}</span></h1>
                        <p className="mt-6 text-lg leading-8 text-gray-600">{t("subtitle")}</p>
                        <div className="mt-10 flex items-start lg:items-left">
                            <a href="tel:+39 351 3841773" className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{t("callUs")}</a> 
                        </div>
                    </div>
                    </div>
                    <div className="w-full lg:w-1/2 mt-0 lg:mt-[-150px]">
                    <a className="flex items-center justify-center lg:justify-end">
                            <img src={require('../../../assets/images/hero.png')}  />
                        </a>
                    </div>
                </div> 
                <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
                    <div className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"></div>
                </div>
            </div> 

    )
}

export default HeroSection;