import { LANGUAGES } from "../../constants";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const Header: FC = () => {

    const { i18n, t } = useTranslation();

    const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const lang_code = e.target.value;
      i18n.changeLanguage(lang_code);
    };

    
    return (
        <header>
            <nav className="bg-white border-gray-200 px-4 z-1000 lg:px-6 py-2.5 dark:bg-gray-800 shadow-lg">
                <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                    <a href="https://matrixalbania.com" className="flex items-center">
                        <img src={require('../../assets/images/matrix.png')} className="mr-3 h-9 sm:h-9" alt="Matrix Logo" />
                    </a>
                    <select defaultValue={i18n.language} onChange={onChangeLang}>
                        {LANGUAGES.map(({ code, label }) => (
                        <option key={code} value={code}>
                            {label}
                        </option>
                        ))}
                    </select>
                    <div className="flex items-center lg:order-2">
                        <a href="tel:+39 351 3841773" className="text-white bg-gradient-to-r from-primary-50 via-primary-600 to-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">{t("callUs")}</a>

                    </div>
                    {/* <div className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
                        <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                            <li>
                                <a href="#" className="block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white" aria-current="page">Kreu</a>
                            </li>
                            <li>
                                <a href="#" className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Rreth Nesh</a>
                            </li>
                            <li>
                                <a href="#" className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Projektet</a>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </nav>
        </header>
    );
}


export default Header;