import { FC } from "react";
import { useTranslation } from "react-i18next";

const Development: FC = () => {
  const { t } = useTranslation();
  
  return (
    <section className="p-8">
      <h2 className="text-4xl font-light tracking-tight text-gray-900 sm:text-6xl">
      {t("ourDesign")}
      </h2>

      <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 gap-4">
        <a
          href="#"
          className="flex p-4 flex-col bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
        >
          <div className="mb-auto">
            <img
              className="object-fill bg-gray-700 p-2 m-4 rounded-lg w-[60px] h-[60px]"
              src={require("../../../assets/images/rocket.png")}
              alt=""
            />
          </div>
          <div className="flex flex-col text-left align-left ml-8  p-4">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {t("uxDriven")}
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            {t("uxDrivenDescr")}
            </p>
          </div>
        </a>
        <a
          href="#"
          className="flex p-4 flex-col  bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
        >
          <div className="mb-auto">
            <img
              className="object-fill bg-blue-400 p-2 m-4 rounded-lg w-[60px] h-[60px]"
              src={require("../../../assets/images/code.png")}
              alt=""
            />
          </div>
          <div className="flex flex-col text-left align-left ml-8  p-4">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {t("developing")}
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            {t("developingDescr")}
            </p>
          </div>
        </a>
      </div>
      <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 gap-4">
        <a
          href="#"
          className="flex p-4 flex-col  bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
        >
          <div className="mb-auto">
            <img
              className="object-fill bg-red-400 p-2 m-4 rounded-lg w-[60px] h-[60px]"
              src={require("../../../assets/images/heart.png")}
              alt=""
            />
          </div>
          <div className="flex flex-col text-left align-left ml-8  p-4">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {t("proven")}
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            {t("provenDescr")}
            </p>
          </div>
        </a>
        <a
          href="#"
          className="flex p-4 flex-col  bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
        >
          <div className="mb-auto">
            <img
              className="object-fill bg-green-500 p-2 m-4 rounded-lg w-[60px] h-[60px]"
              src={require("../../../assets/images/protect.png")}
              alt=""
            />
          </div>
          <div className="flex flex-col text-left align-left ml-8  p-4">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {t("security")}
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            {t("securityDescr")}
            </p>
          </div>
        </a>
      </div>
      <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 gap-4">
        <a
          href="#"
          className="flex p-4 flex-col  bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
        >
          <div className="mb-auto">
            <img
              className="object-fill bg-yellow-500 p-2 m-4 rounded-lg w-[60px] h-[60px]"
              src={require("../../../assets/images/check.png")}
              alt=""
            />
          </div>
          <div className="flex flex-col text-left align-left ml-8  p-4">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {t("reviewers")}
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            {t("reviewersDescr")}
            </p>
          </div>
        </a>
        <a
          href="#"
          className="flex p-4 flex-col  bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
        >
          <div className="mb-auto">
            <img
              className="object-fill bg-primary-50 p-2 m-4 rounded-lg w-[60px] h-[60px]"
              src={require("../../../assets/images/kyc.png")}
              alt=""
            />
          </div>
          <div className="flex flex-col text-left align-left ml-8  p-4">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {t("quality")}
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            {t("qualityDescr")}
            </p>
          </div>
        </a>
      </div>
    </section>
  );
};

export default Development;
