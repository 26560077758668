import { FC } from "react";
import { useTranslation } from "react-i18next";

const Projects: FC = () => {
  const { t } = useTranslation();
  
  return (
    <section className="bg-white dark:bg-gray-900" id="projects">
      <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        <img
          className="w-full dark:hidden"
          src={require("../../../assets/images/projects.png")}
          alt="dashboard image"
        />
        <img
          className="w-full hidden dark:block"
          src={require("../../../assets/images/projects.png")}
          alt="dashboard image"
        />
        <div className="mt-4 md:mt-0">
          <h2 className="mb-4 text-4xl tracking-tight font-medium text-gray-900 dark:text-white">
          {t("leading")}
          </h2>
          <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
          {t("development")}
          </p> 
        </div>
      </div>

      <div>
        <h2 className="m-16 text-4xl font-light tracking-tight text-gray-900 sm:text-6xl">
          {t("people")}
        </h2>
  

        <div className="bg-primary-700 p-8 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 w-full gap-8 overflow-hidden">
          <a href="https://www.greenair.eco/en" target="_blank" className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
            <img
              style={{
                height: "100px",
                width: "100%",
                objectFit: "contain",
              }}
              className="w-full h-[150px]"
              src={require("../../../assets/images/greenair.png")}
              alt="..."
            />
          </a>

          <a href="https://www.klima-quote.de/" target="_blank" className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
            <img
              style={{
                height: "100px",
                width: "100%",
                objectFit: "contain",
              }}
              className="w-full h-[150px]"
              src={require("../../../assets/images/Klima-Quote.de_Logo.png")}
              alt="..."
            />
          </a>

          <a href="https://wirkaufendeinzertifikat.de/" target="_blank" className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
            <img
              style={{
                height: "100px",
                width: "100%",
                objectFit: "contain",
              }}
              className="w-full h-[150px]"
              src={require("../../../assets/images/wirkaufendeinzertifikat.png")}
              alt="..."
            />
          </a>
          <a href="https://www.standard.al" target="_blank" className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
            <img
              style={{
                height: "100px",
                width: "100%",
                objectFit: "contain",
              }}
              className=" w-full h-[150px]"
              src={require("../../../assets/images/standard.png")}
              alt="..."
            />
          </a>

          <a href="https://thesimplegreek.uk/" target="_blank" className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
            <img
              style={{
                height: "80px",
                width: "100%",
                objectFit: "contain",
              }}
              className="w-full h-[150px]"
              src={require("../../../assets/images/simple.png")}
              alt="..."
            />
          </a>

          <a href="https://www.hopipropertyservicesltd.com/" target="_blank" className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
            <img
              style={{
                height: "80px",
                width: "100%",
                objectFit: "contain",
              }}
              className="w-full h-[150px]"
              src={require("../../../assets/images/hopi.png")}
              alt="..."
            />
          </a>
        </div>

        <div className="p-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full gap-3 overflow-hidden">
          <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
            <img
              className=" w-full h-[250px] rounded-lg "
              src={require("../../../assets/images/dashboard.png")}
              alt="..."
            />
          </a>

          <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
            <img
              className="w-full h-[250px] rounded-lg "
              src={require("../../../assets/images/dashbaord-2.png")}
              alt="..."
            />
          </a>

          <a className="w-full hover:-translate-y-1 hover:scale-110 duration-300">
            <img
              className="w-full h-[250px] rounded-lg "
              src={require("../../../assets/images/dashboard-3.png")}
              alt="..."
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Projects;
