 
import Header from 'components/header/header';
import './App.css';
import React from 'react';
import Home from 'components/home';
import Footer from 'components/footer/footer';

function App() {
  return (
    <div className="App">
      <Header /> 
      <Home />
      <Footer />
    </div>
  );
}

export default App;
